//Adding custom button styles
.u-button{
    &--padding {
        line-height: 24px;
        padding: 12px 16px 12px 16px;
    }
    &--form--disabled{
        opacity: 0.7;
    }
    &--color-white {
        color: white;
    }
    &--link{
        border: none;
        text-decoration: underline;
        background: unset;
        color: #069;
    }
    &--orange {
        background-color: $t-color-orange;
        border-color: transparent;
    }

    // For buttons used alongside Selectron
    &--selectron {
        font-size: $context--font-size-xs;
    }
    @media (min-width: 768px) {
        &--selectron {
            height: $selectron--height;
        }
    }
    @media (hover: none) and (pointer: coarse) {
        &--selectron {
            margin-bottom: 10px;
        }
    }
}

