.c-news-iframe {
    position: relative;
    width: 100%;
    overflow: hidden;
}
.c-news-iframe__content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
