// overrides for slick.js
// https://kenwheeler.github.io/slick/

//slick arrow
.slide-arrow {
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -15px;
}
.next-arrow {
    right: -50px;
}

.slick-slider--align-heights {
    .slick-track {
        display: flex;
        align-items: stretch;
    }
    .slick-slide {
        flex: 1; // prevent the width from stretching really wide
    }
    .slick-slide:not(.slick-active) {
        height: 0; // make non-visible cards not affect height
    }
    .slick-slide.slick-active {
        height: auto; // make the slide take advantage of stretch
        display: flex; // make the contents of the slide full height
    }
}

.slider-controls {
    position: absolute; 
    right: 0; 
    display: flex; 
    top: calc(50% - 40px); 
    flex-direction: column;
}