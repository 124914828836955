// Form error message styles

.c-form__field__error {
    color: #c40606;
    font-weight: 600;
    line-height: 1.2;
    display: flex;
    align-items: center;
    width: 100%;
}
.c-form__field__error__icon {
    margin-right: 8px;
}