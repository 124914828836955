.c-table{
    border-color: $t-color-greyscale-300;
    border-style: solid;
    .c-table--head{
        .c-table--head--row{
            border-bottom: 0;
            .c-table--head--row--data {
                position: relative; 
                padding: 0.75rem 1.5rem 0.75rem 1.5rem;
                color: $t-color-primary-white;
                background-color: $t-color-brand-blue;
                border-right-color: $t-color-primary-universal-dark-blue;
            }

        }
    }
    .c-table--body{
        .c-table--body--row{
            border-top: 1px solid $t-color-greyscale-300;
            .c-table--body--row--data{
                padding: 0.75rem 1.5rem 0.75rem 1.5rem;
                border-right: 0;
            }
        }
    }    
    .c-table--body--row--data--accordion{
        padding: 0.75rem 1.5rem 0.75rem 1.5rem
    }
}