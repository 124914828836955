// Modify gloabl autocomplete styles

.c-autocomplete-form{
    position: relative;
}
.c-results-container {
    width: 100%;
    position: absolute;
    z-index: 10;
}
.c-results-container__result {
    padding: 10px; 
    color: #222;                               
}
.c-results-container__result__highlight{
    color: #0275B0;
}