// Box

.c-box {
    background-color: #f2f2f2;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 3rem;
    padding: 1.6rem;
}
.c-box--full-width,
.c-box--news-archive {
    padding-top: 32px;
    padding-bottom: 32px;
}
// Mission statement box
.c-box--quote {
    position: relative;
}
.c-box--quote p::before {
    content: '\201c';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 1px;
    font: 6em/1.08em Georgia, serif;
    color: #fff;
    text-align: center;
}
.c-box--quote p {
    font-family: Harding,Palatino,serif; 
    font-style: italic;
}
// Client logo box in the sidebar
.c-box--client-info a {
    text-decoration: underline;
}

// RESPONSIVE CSS

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
    .c-box--quote p {
        font-size: 1.5rem;
        margin-left: 32px;
        margin-right: 32px;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
    .c-box--quote p {
        font-size: 1.6875rem;
        margin-left: 48px;
        margin-right: 48px;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
}

