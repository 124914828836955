// Card styles

.app-news-row__item{
    width: 100%;
    border-bottom: 1px solid #b3b3b3;
    padding-bottom: 16px;
    margin-bottom: 16px;
    &--no--border{
        border-bottom: 0;
    }
}
.app-news-row__item--major {
    border: 0;
    width: 100%;
}
.app-news-row__item .c-card__image {
    border: solid 1px #d5d5d5;
    max-height: 226px;
    overflow: hidden;
    margin-bottom: 16px;
}
.app-news-row__item--major .c-card--major .c-card__image {
    border: 0;
    max-height: 395px;
    overflow: hidden;
    margin-bottom: 16px;
}
.app-news-row__item--major .c-author-list {
    color: inherit;
}
// Homepage: NI supplement
.app-collections-row__item {
    border-bottom:1px solid #b3b3b3;
    padding-bottom:16px;
    margin-bottom:16px;
 }
.app-collections-row__item .c-card__image {
    display: block;
    margin-bottom: 16px;
    -webkit-box-flex: 0;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}
.app-collections-row__item .c-card__image > img {
    width: 100%;
}
.app-collections-row__item .c-card__body{
    flex: 1 1 50%;
} 
// Research highlights of partners
.app-reviews-row__item {
    width: 100%;
    border-bottom: 1px solid #b3b3b3;
    padding-bottom: 16px;
    margin-bottom: 16px;
    &__grid-span--two{
        @extend .app-reviews-row__item;
        grid-column: span 2;
    }
}

.app-reviews-row__item .c-card__image {
    max-height: 234px;
    overflow: hidden;
    margin-bottom: 16px;
}

// EIP 
.c-highlight-row__item {
    width: 100%;
    border-bottom: 1px solid #b3b3b3;
    padding-bottom: 16px;
}

// New Template for Annual Tables
.c-card--border{
    border-bottom: none;
    border: 0.0625rem solid $t-color-greyscale-400;
    margin-bottom: 1.75rem;
}

.c-card-title--link{
    text-decoration: none;
}

.c-card--border .c-card__title{
    margin-left: 1rem;
}

.c-card--border .c-card__image {
    border: none;
}

// RESPONSIVE CSS

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
    // News grid on home/news landing
    .app-news-row {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-column-gap: 16px;
    }
    .app-news-column--three {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-column-gap: 16px;
    }
    .app-news-row__item--major {
        grid-column: 1/3;
        grid-row: 1/3;
        border-bottom: none;
        padding-bottom: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .app-news-row__item--major .c-card--major .c-card__image {
        border: 0;
        height: 335px;
        overflow: hidden;
    }
    // Homepage: Research Highlights of partners
    .app-reviews-row__grid {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-column-gap: 16px;
    }
    .app-news-row__item .c-card__image {
        max-height: 164px;
    }  
    // EIP
    .c-highlight-row {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-column-gap: 16px;
    }
    .c-highlight-row .c-card__image {
        max-height: 82px;
        overflow: hidden;
    }  
    .app-collections-row__item .c-card__body{
        flex: 1 1 50%;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
    // News grid on home/news landing
    .app-news-row {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-column-gap: 16px;
    }
    .app-news-column--three {        
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-column-gap: 16px;
    }
    .app-news-row__item--major {
        grid-column: 1/3;
        grid-row: 1/3;
        border-bottom: none;
        padding-bottom: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: unset;
    }
    // News grid without a main card
    .app-news-row--no-main {
        grid-template-rows: repeat(1,1fr);
    }
    .app-news-row__item .c-card__image {
        max-height: 90px;
    }   
    // Research highlights of partners
    .app-reviews-row__item .c-card__image {
        max-height: 79px;
    }
    // Homepage: Research Highlights of partners
    .app-reviews-row__grid {
        grid-template-columns: repeat(5,1fr);
    }
    // Homepage: NI supplement
    .app-collections-row__item .c-card {
        -webkit-box-orient:horizontal;
        -webkit-box-direction:reverse;
        -ms-flex-direction:row-reverse;
        flex-direction:row-reverse;
    }
    .app-collections-row__item .c-card__image {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto ;
        margin:0 0 0 16px;
        width: auto;
    }
    .app-collections-row__item .c-card__image > img {
        max-height: 250px;
        width: auto;
    }
    .app-collections-row__item .c-card__body{
        flex: 1 1 50%;
    }
    // EIP 
    .c-highlight-row .c-card__image {
        max-height: 82px;
    }  
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {   
    .app-news-row__item .c-card__image {
        max-height: 125px;
    }  
    // Research highlights of partners
    .app-reviews-row__item .c-card__image {
        max-height: 99px;
    }
    // EIP
    .c-highlight-row .c-card__image {
        max-height: 109px;
    } 
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
    // News grid on home/news landing
    .app-news-row {
        display: grid;
        grid-template-columns: repeat(4,1fr);
        grid-column-gap: 16px;
    }
    .app-news-column--three {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-column-gap: 16px;
    }
    .app-news-row__item--major {
        grid-column: 1/3;
        grid-row: 1/3;
        border-bottom: none;
        padding-bottom: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .app-news-row__item .c-card__image {
        max-height: 160px;
    } 
    // Research highlights of partners
    .app-reviews-row__item .c-card__image {
        max-height: 126px;
    }
    // EIP
    .c-highlight-row .c-card__image {
        max-height: 126px;
    }   
    .app-collections-row__item .c-card__body{
        flex: 1 1 50%;
    }
}