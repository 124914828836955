// Select menu

.c-select-menu--full .c-select-menu__button {
    width: 100%;
}
.c-select-menu__button {
    position: relative;
    border: 1px solid #DADADA;
    font-size: 1rem;
}
.c-select-menu__label {
    font-weight: 700;
    white-space: nowrap;
    margin-bottom: 5px;
    margin-right: 1px;
}
.c-select-menu__label--small {
    font-size: 0.8125rem;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 1px;
}
.c-select-menu-caret {
    position: absolute;
    right: 1px;
    top: 30%;
    transition-duration: .2s;
}
.c-select-menu__button[aria-expanded=true] .c-select-menu-caret {
    transform: rotate(180deg);
    display: inline-block;
}
.c-select-menu .btn-secondary,
.c-select-menu .btn-secondary:hover{
    background-color: #fff !important;
    border-color: #ced4da;
    box-shadow: none;
    color: #222 !important;
    text-align: left;
}


.c-select-menu .btn-secondary:focus,
.c-select-menu .btn-secondary:active,
.c-select-menu .btn-secondary:not(:disabled):not(.disabled):active:focus,
.c-select-menu .btn-secondary:not(:disabled):not(.disabled).active:focus,
.c-select-menu .show > .btn-secondary.dropdown-toggle,
.c-select-menu .btn-secondary:not(:disabled):not(.disabled):active, 
.c-select-menu .btn-secondary:not(:disabled):not(.disabled).active, 
.c-select-menu .show > .btn-secondary.dropdown-toggle
.c-select-menu .show > .btn-secondary.dropdown-toggle:focus
.c-select-menu .btn-secondary:not(:disabled):not(.disabled):active, 
.c-select-menu .btn-secondary:not(:disabled):not(.disabled).active, 
.c-select-menu.show > .btn-secondary.dropdown-toggle {
    box-shadow: none !important; // Use outline instead
    outline: unset;
    border: 1.5px solid #0275B0;
}
// dropdown styles
.c-select-menu .dropdown-menu {
    background-color: #fff;
    border: 1px solid #ced4da;
    color: #222;
    width: 100% !important;
    min-width: fit-content;
    transform: translate3d(0px, 50px, 0px) !important;
    margin: 0px;
}
.c-select-menu .dropdown-item {
    color: #222;
    font-size: 1.125rem;
    padding: 8px 16px;
    white-space: normal;
}
.c-select-menu .dropdown-item.selected {
    display: none;
}
.c-select-menu .dropdown-item:hover {
    cursor: pointer;
    text-decoration: underline;
}.c-select-menu .dropdown-item:hover,
.c-select-menu .dropdown-item:focus {
    background-color: #e7eff4;
}
