// Accessible sortable table
.c-sortable-table td.sorted {
    background-color: rgba(255, 255, 0, 0.15);
}
.c-sortable-table  th {
    padding: 0;
    text-indent: 0;
}
.c-sortable-table th > button {
    background: transparent;
    border: 1px solid transparent;
    color: inherit;
    font: inherit;
    text-align: left;
    cursor: pointer;
    padding: 0.5em;
    width: 100%;
    display: grid;
    grid-template-columns: minmax(1em, max-content) .65em auto;
    grid-template-areas: "t a x" "t d x";
}
.c-sortable-table th > button > span {
    grid-area: t;
    padding-right: .5em;
}
.c-sortable-table th > button > .asc {
    grid-area: d;
    align-self: center;
}
.c-sortable-table th > button > .des {
    grid-area: a;
    align-self: center;
}
.c-sortable-table th > button::after {
    content: "";
    grid-area: x;
}
.c-sortable-table th > button:hover {
    color: $t-color-white;
    outline: none;
}
.c-sortable-table th > button:focus {
    color: $t-color-white;
    outline: 3px solid $t-button-color-hover-outline;
}
.c-sortable-table th > button svg.sort {
    fill: transparent;
    stroke: $t-color-white;
    max-width: .65em;
    max-height: 1.2em;
}
.c-sortable-table [aria-sort="ascending"] > button svg.asc {
    stroke: $t-color-white;
    fill: $t-color-white;
}
.c-sortable-table [aria-sort="descending"] > button svg.des {
    stroke: $t-color-white;
    fill: $t-color-white;
}
.c-sortable-table th:focus > button svg.sort, th:hover > button svg.sort, th:focus-within > button svg.sort {
    stroke: $t-color-white;
}
.c-sortable-table [aria-sort="ascending"] > button:focus svg.asc,
.c-sortable-table [aria-sort="ascending"] > button:hover svg.asc,
.c-sortable-table [aria-sort="descending"] > button:focus svg.des,
.c-sortable-table [aria-sort="descending"] > button:hover svg.des {
    stroke: $t-color-white;
    fill: $t-color-white;
}
