// Sajari search

.sj-input__input.css-zgmajg,
.sj-input__input.css-1i32q0y {
    padding: 8px 9px;
}

// Overwrite Sajari search results styles

#results-search-response .sj-summary {
    font-size: 16px;
    margin-bottom: 32px;
}
#results-search-response .sj-summary__results-text,
#results-search-response .sj-summary__search-term {
    color: #222;
}
#results-search-response ol li {
    border-bottom: solid 1px #ccc;
    padding-bottom: 16px;
    margin-bottom: 16px;
}
#results-search-response .sj-results__result__image {
    background-position: left top;
    background-size: contain;
}
#results-search-response .sj-results__result__title > h3 {
    font-weight: 700;
}
#results-search-response .sj-results__result__link,
#results-search-response .sj-summary__query-time {
    display: none;
}
// Paginator
.sj-paginator__page-button {
    color: #069 !important;
    line-height: normal !important;
}
.sj-paginator__page-number {
    color: #069 !important;
    border: solid 1px #069 !important;
    border-radius: 2px !important;
    width: auto !important;
    height: auto !important;
}
.sj-paginator__page-number--current {
    background-color: #888 !important;
    border-radius: 2px !important;
    color: #fff !important;
    width: auto !important;
    height: auto !important;
}
.sj-paginator ul li {
    font-size: 1rem;
    line-height: 1.5;
    margin-right: 16px;
}
.sj-paginator ul li:last-child {
    margin-right: 0;
}
.sj-paginator__page-number:hover,
.sj-paginator__page-number--current:hover {
    color: #fff !important;
    background-color: #069 !important;
    text-decoration: underline !important;
}

// RESPONSIVE CSS

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
    #results-search-response .sj-results__result__image {
        background-position: center center;
        background-size: auto;
        min-width: 230px;
        min-height: 126px;
        width: 230px;
        height: 126px;
    }
}