@font-face {
    font-family: 'Harding';
    src: url('../../dist/fonts/HardingText-Regular-Web.woff2') format('woff2'), url('../../dist/fonts/HardingText-Regular-Web.woff') format('woff');
    font-display: fallback;
}
@font-face {
    font-family: 'Harding';
    src: url('../../dist/fonts/HardingText-Bold-Web.woff2') format('woff2'), url('../../dist/fonts/HardingText-Bold-Web.woff') format('woff');
	font-weight: 700;
    font-display: swap;
}
@font-face {
	font-family:'Harding';
	src: url('../../dist/fonts/HardingText-RegularItalic-Web.woff2') format('woff2'), url('../../dist/fonts/HardingText-RegularItalic-Web.woff') format('woff');
	font-display: swap;
	font-style: italic;
}
@font-face {
	font-family:'Harding';
	src: url('../../dist/fonts/HardingText-BoldItalic-Web.woff2') format('woff2'), url('../../dist/fonts/HardingText-BoldItalic-Web.woff') format('woff');
	font-display: swap;
	font-weight: 700;
	font-style: italic;
}


