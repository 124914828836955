
// Custom styles for panels 
.c-panel{
    padding: 1rem;
    position: relative;
    &--gray{        
        border: 1px solid $t-color-greyscale-400;
        background-color: $t-color-greyscale-200;
    }
    &--grayscale900{        
        background-color: $t-color-greyscale-900;
    }
    &--blue{
        background: $t-color-brand-blue;
        color: $t-color-white;
        padding: 2rem;
    }
    &--bordered{
        &--grayscale-400{
            border: 1px solid $t-color-greyscale-400;
        }
        &--grayscale-300{
            border: 1px solid $t-color-greyscale-300;
        }
    }
    &__button--bottom{        
        position: absolute;
        right: 1.5rem;
        bottom: 1rem;
    }
    &--bg--img{        
        background-image: url("../../dist/img/hero-pattern.svg");
        background-repeat: no-repeat;
        background-position: 100%;
        background-size: contain;
    }
    &__header {
        padding: 0;
        box-shadow: 0 0 10px 0 $t-color-dark-gray;
    
        &--top {
            background-color: $t-color-light-blue-3;
        }
    }
    &--black--bg--img{
        background: #000;
        color: $t-color-white;
        padding: 2rem;
        background-image: url("../../dist/img/hero-pattern-2.svg");
        background-repeat: no-repeat;
        background-position: center right -200px;
        background-size: contain;
        &--title {
            padding-right: 100px;
            margin: 63px 0;
            line-height: 2.2rem;
        }
    }
}

