//Note: This will be our own component (forked from original selectron project)
$color_1: #666;
$color_2: #b3b3b3;
$color_3: #333333;
$color_4: #017E71;
$color_5: #ccc;
$color_6: #666666;
$color_7: #fff;
$font-family_1: sans-serif;
$background-color_1: transparent;
$background-color_2: #fff;
$background-color_3: #f70c36;
$background-color_4: #363636;
$background-color_5: #434343;
$border-color_1: #999;
$border-color_2: #fff transparent transparent transparent;

/* width */
/* Track */
/* Handle */
.selectron {
    color: $color_1;
    display: inline-block;
    font-family: $font-family_1;
    font-size: 14px;
    max-width: 100%;
    min-width: 100%;
    position: relative;
    width: 100%;
}
.selectronselect {
    display: none;
}
.selectron--disabled {
    opacity: .5;
    pointer-events: none;
}
.selectron--is-touch {
    /* Enhance the select element for touch devices */
    .selectron__select {
        color: $color_1;
        font-family: $font-family_1;
        font-size: 14px;
        height: 100%;
        padding: 0 40px 0 12px;
        visibility: visible;
        width: 100%;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    /* Container styles */
    background-color: $background-color_2;
    border: 1px solid #ccc;
    box-sizing: border-box;
    color: $color_2;
    cursor: pointer;
    font-family: $font-family_1;
    font-size: 14px;
    line-height: 1.4;
    min-height: $selectron--height;
    outline: none;
    overflow: hidden;
    padding: 12px 16px;
    position: relative;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    /* Focus states */
    &::-moz-focus-inner {
        border: none;
    }
    &:focus {
        border-color: $border-color_1;
    }

    /* Dropdown arrow */
    &:before {
        content: "";
        border-bottom: 2px solid #000;
        border-left: 2px solid #000;
        height: 12px;
        border-radius: 0 0 0 0px;
        width: 12px;
        position: absolute;
        top: 50%;
        right: 24px;
        margin-top: -2px;
        -webkit-transform: rotate(-45deg) translate(50%, -50%);
        -ms-transform: rotate(-45deg) translate(50%, -50%);
        -o-transform: rotate(-45deg) translate(50%, -50%);
        transform: rotate(-45deg) translate(50%, -50%);
    }

    /* Active state enhancement */
    &:active {
        background-color: #f5f5f5;
    }
}

.selectron__select {
    background-color: $background-color_1;
    border: none;
    height: 0;
    left: 0;
    outline: none;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 0;
}
.selectron__trigger {
    background-color: $background-color_2;
    border: 1px solid #ccc;
    box-sizing: border-box;
    color: $color_2;
    cursor: pointer;
    font-family: $font-family_1;
    font-size: 14px;
    outline: none;
    overflow: hidden;
    padding: 8px 12px 8px 12px;
    position: relative;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    height: $selectron--height;
    &::-moz-focus-inner {
        border: none;
    }
    &:focus {
        border-color: $border-color_1;
    }
    &:before {
        content: "";
        border-bottom: 2px solid #000;
        border-left: 2px solid #000;
        height: 10px;
        border-radius: 0 0 0 0px;
        width: 10px;
        position: absolute;
        top: 50%;
        right: 16px;
        margin-top: -1px;
        -webkit-transform: rotate(-45deg) translate(50%, -50%);
        -ms-transform: rotate(-45deg) translate(50%, -50%);
        -o-transform: rotate(-45deg) translate(50%, -50%);
        transform: rotate(-45deg) translate(50%, -50%);
    }
}
.selectron--is-touch--is-filled {
    color: $color_1;
}
.selectron__trigger--is-filled {
    color: $color_1;
}
.selectron--is-touch--is-open {
    border-color: $border-color_1;
    border-radius: 4px 4px 0 0;
    color: $color_1;
}
.selectron__trigger--is-open {
    border-color: $border-color_1;
    border-radius: 4px 4px 0 0;
    color: $color_1;
}
.selectron--is-touch--is-overflowing {
    border-radius: 0 0 4px 4px;
}
.selectron__trigger--is-overflowing {
    border-radius: 0 0 4px 4px;
}
.selectron__search {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
    border: 1px solid #999;
    box-sizing: border-box;
    color: $color_1;
    font-family: $font-family_1;
    font-size: 14px;
    left: 0;
    opacity: 0;
    outline: none;
    padding: 8px 12px 8px 12px;
    position: absolute;
    visibility: hidden;
    width: 100%;
    &:focus {
        border: 1px solid #0275B0;
        outline: 2px solid #D6EBF5;
    }
}
.selectron__search--is-overflowing {
    border-top: 1px solid #999;
    top: auto;
}
.selectron__search--is-overflowing+.selectron__options {
    top: auto;
}
.selectron__search--is-open {
    opacity: 1;
    visibility: visible;
}
.selectron__options {
    background-color: $background-color_2;
    border-radius: 0 0 4px 4px;
    border: 1px solid #999;
    border-top: none;
    box-sizing: border-box;
    left: 0;
    list-style: none;
    margin: 0;
    max-height: 390px;
    opacity: 0;
    overflow: auto;
    padding: 0;
    position: absolute;
    text-align: left;
    top: 100%;
    visibility: hidden;
    width: 100%;
    z-index: 50;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        width: 3px;
        height: 300px;
        border-radius: 100px;
        margin-right: 50px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
        background: #DADADA;
    }
}
.selectron__options--is-open {
    opacity: 1;
    visibility: visible;
}
.selectron__options--is-overflowing {
    border-bottom: none;
    border-radius: 4px 4px 0 0;
    border-top: 1px solid #999;
    bottom: 100%;
    top: auto;
}
.selectron__option {
    cursor: pointer;
    line-height: 1.2;
    overflow: hidden;
    padding: 8px 12px 8px 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover{
        background-color: #e7eff4;
    }
}
.selectron__option--optgroup {
    color: $color_3;
    &:hover{
        background-color: #e7eff4;
    }
}
.selectron__option--is-selected {
    &::before {
        font-weight: bold;
        content: "✓";
        display: inline-block;
        left: 95%;
        top: 18px;
        font-size: 15px;
        color: $color_4;
        float: right;
    }
}
.selectron__option--is-disabled {
    color: $color_5;
    pointer-events: none;
}
.selectron__option[data-value=""] {
    color: $color_5;
}
.selectron__option--is-hidden {
    display: none;
}
.selectron__no-results {
    line-height: 1.2;
    padding: 8px 12px 8px 12px;
}
.selectron__option-group {
    color: $color_6;
    cursor: default;
    padding: 8px 12px 8px 12px;
    border-top: 1px solid #DADADA;
    font-weight: 500;
    text-transform: uppercase;
}
.selectron__icon {
    display: inline-block;
    margin: 0 10px 0px 0;
    max-width: 50px;
    vertical-align: middle;
}
.selectron--block {
    .selectron__trigger {
        &:before {
            background-color: $background-color_3;
            border: none;
            height: 100%;
            margin: 0;
            right: 0;
            top: 0;
            width: 50px;
            -webkit-transform: none;
            -ms-transform: none;
            -o-transform: none;
            transform: none;
        }
        &:after {
            border-color: $border-color_2;
            border-style: solid;
            border-width: 5px 5px 0 5px;
            content: "";
            height: 0;
            width: 0;
            position: absolute;
            top: 50%;
            margin-top: -2.5px;
            right: 20px;
        }
    }
}
.selectron--dark {
    color: $color_7;
    .selectron__trigger {
        background-color: $background-color_4;
        border: none;
        color: $color_1;
        &:focus {
            background-color: $background-color_5;
        }
    }
    .selectron__trigger--is-filled {
        color: $color_7;
    }
    .selectron__trigger--is-open {
        background-color: $background-color_5;
        color: $color_7;
    }
    .selectron__options {
        background-color: $background-color_4;
        border: none;
    }
    .selectron__option[data-value=""] {
        color: $color_1;
    }
}