// List group styles

.c-list-group__item {
    padding-bottom: 16px;
    margin-bottom: 16px;
}
.c-list-group__item .c-card {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}
.c-list-group__item .c-card__image {
    margin: 0 0 0 16px;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 40%;
    flex: 1 0 40%;
    max-width: 230px;
    height: 126px;
    overflow-y: hidden;
}

// List group with no meta column (RH, Altmetric)
.c-list-group__item .c-card.c-card--no-meta {
    padding-left: 0;
}
// Reading order fix on article with altmetric
.c-list-group__item .c-card.c-card--altmetric {
    padding-left: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}
// List group with Altmetric embed
.c-list-group__item .c-card__altmetric {
    margin: 0 0 0 16px;
    flex: 1 0 30%;
    height: 120px;
    overflow-y: hidden;
}
.c-list-group__item .c-card__altmetric .altmetric-embed {
    position :absolute; 
    right: 0;
}

// RESPONSIVE CSS

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
    .c-list-group__item .c-card__image {
        border: solid 1px #d5d5d5;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
    .c-list-group__item .c-card {
        position: relative;
        padding-left: 230px;
    }
    .c-list-group__item .c-meta {
        position: absolute;
        max-width: 214px;
        left: 0;
        top: 0;
    }
    // NI supplement TOC
    .c-list-group--no-image .c-list-group__item {
        min-height: 63px;
    }
}