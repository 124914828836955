.u-scientific-discipline-background{
    &--3f5b99d1c2d24a7db57e1e74{
        background-color: var(--scientific-discipline-3f5b99d1c2d24a7db57e1e74); // Biological science 
    }
    &--6a7ed890b6b541dab8d602c1{
        background-color: var(--scientific-discipline-6a7ed890b6b541dab8d602c1); // chemistry 
    }
    &--7c4a8d09ca3762af61e59520{
        background-color: var(--scientific-discipline-7c4a8d09ca3762af61e59520); // Earth & environmental sciences 
    }
    &--9a0364b9e99bb480dd25e1f0{
        background-color: var(--scientific-discipline-9a0364b9e99bb480dd25e1f0); // Health sciences
    }
    &--e7f6c011776e8db7cd330b54{
        background-color: var(--scientific-discipline-e7f6c011776e8db7cd330b54); // Physical sciences
    }
    &--4e2a87f2b3c35b6dc78e4f23{
        background-color: var(--scientific-discipline-4e2a87f2b3c35b6dc78e4f23); // Applied sciences
    }
    &--b5a4f3d2c1e8090876543210{
        background-color: var(--scientific-discipline-b5a4f3d2c1e8090876543210); // Social sciences
    }
}
