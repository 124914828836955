
// custom styles for form elements
.c-input, .c-select{
    border: 1px solid $t-color-greyscale-400;
    width: 100%;
    font-size: 1rem;
    padding: 12px 16px 12px 16px;
    line-height: 24px;
    &:focus{
        outline: unset;
        border: 1.5px solid #0275B0;
    }
}
.c-form-group{
    select, button, input, a{
        padding: 8px 12px 8px 12px;
    }
}
.c-forms__label--checkbox::before{
    border-radius: 0;
}
.c-forms__label--inline::before{
    width: 1rem;
    height: 1rem;
    border: 2px solid $t-color-primary-universal-dark-blue;
}
:checked + .c-forms__label--radio::before{
    background: radial-gradient($t-color-primary-universal-dark-blue 0%, #01324b 55%, $t-color-primary-white 40%);
}
.c-form__error{
    color: $t-color-information-redscale-600;
    font-weight: 600;
    line-height: 1.2;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
}