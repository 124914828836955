.c-bubble-chart{
    &-tooltip{
        position: absolute;
        pointer-events: none;
        background: $t-color-white;
        border: 1px solid $t-color-greyscale-400;
        border-radius: 4px;
        box-shadow: 0 2px 6px rgba(0,0,0);
        z-index: 10;
        &-header {
            color:  $t-color-white;
            padding: 10px;
            border-radius: 4px 4px 0 0;
            font-weight: bold;
            font-size: calc(1em - 2px);
        }
        &-body {
            padding: 10px;
            font-size: 14px;
            font-family: sans-serif;
        }
    }
}