// Nature Index misc styles

///// Common styles

// Page background
.bkg-white {
    background-color: $t-color-white;
}
main {
    margin-bottom: 60px;
}
.c-more-link {
    font-size: 1rem;
}
.c-skip-table__link {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    color: $t-color-white;
    background: $t-context--color-blue;
    padding: 8px;
    font-size: 1.4rem;
}
.c-skip-table__link:hover {
    color: $t-color-white;
}
.c-skip-table__link:focus {
    height: auto;
    position: static;
    width: auto;
}
// Heading
.c-slice-heading {
    border-top: 5px solid #222;
    border-bottom: 1px solid #222;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 24px;
    line-height: 1.4;
}

.c-slice-heading--bottom{
    border-bottom: 1px solid $t-color-white;
    padding-bottom: 16px;
    margin-bottom: 16px;
    line-height: 1.4;
}

abbr[title] {
    border-bottom: dotted 1px #222;
}
// Footer
.c-footer__button {
    background-color: transparent;
    color: #d5d5d5;
    border: 0;
}
.c-footer__button:hover {
    text-decoration: underline;
}

.navbar {
    margin-top: 5px;
    margin-bottom: 5px;
}
//nav tabs
.nav-tabs .nav-link.active{
    border-bottom: solid 2px $t-color-blue-900;
    color: $t-color-blue-900;
    font-weight: bold;
}
.nav-tabs .nav-link.active{
    outline: none;
}
// Banner ad
.c-ad {
    display: block;
}
.c-ad--300x250 .c-ad__inner {
    padding-top: 1px;
    padding-bottom: 1px;
    min-height: auto;
}

// Utility
.u-full-height {
    height: 100%;
}
.u-icon {
    vertical-align: middle;
}
.u-vertical-align-text--bottom{
    vertical-align: text-bottom;
}
.u-line-height{
    line-height: 1.5rem;
    font-size: 1.125rem;
}
.u-cursor-pointer{
    cursor: pointer;
}
.u-color-gray{
    color: $t-color-greyscale-600;
}

// note styles to handle old EIPs generated from the NI admin
.note {
    color: #6c757d;
}

///// Page styles

// Homepage
.c-hero {
    flex-direction: row;
}
.c-search {
    background-color: #eee;
}
.c-search__inner {
    color: #222;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 30px;
    padding: 16px;
}

// Data tables
.c-data-table__number {
    width: 100px;
}
.c-data-table--arrow-up {
    color: #3c763d;
}
.c-data-table--arrow-down {
    color: #c40606;
}

// Institution profile, Country profile
.c-profile__text {
    font-size: 1rem;
    line-height: 1.6;
}

.c-chart__note {
    font-size: 0.75rem;
    line-height: 1.4;
}
.c-chart__label-domestic {
    color: #666; 
    margin-bottom: -18px;
}
.c-chart__label-international {
    color: #666; 
}

table caption {
    color: #222;
    font-size: 0.9375rem;
    line-height: 1.3;
}
.c-subject-table {
    border-top: solid 1px #d5d5d5;
    border-bottom: solid 1px #d5d5d5;
}
.c-subject-table tr {
    border-top: 0;
}
.c-subject-table__subject-row {
    border-top: solid 1px #d5d5d5 !important;
}
.c-subject-table__number,
.c-journal-table__number,
.c-article-table__number,
.c-aauthor-table__number {
    width: 80px;
}
.c-journal-table tr {
    border-top: 0;
}
.c-journal-table__journal,
.c-journal-table .c-journal-table__journal {
    padding-left: 37.5px;
}
.c-article-table__journal,
.c-article-table .c-article-table__journal {
    padding-left: 67.5px;
}
.c-label__color {
    padding-left: 10px;
    cursor: pointer;
    height: 12px;
    width: 12px;
    display: inline-block;
    margin-right: 10px;
}

.c-label__color--domestic {
    border-left-color: darken(#638891, 5%);
    border-left-width: 25px;
}
.c-label__color--international {
    border-left-color: #97d5c9;
    border-left-width: 25px;
}
.c-label__color--current {
    border-left-color: #bab1d4;
    border-left-width: 25px;
}
.c-label__color--foreign {
    border-left-color: #97d5c9;
    border-left-width: 25px;
}
.c-top10-table__number {
    width: 80px;
}
.c-top10-table__rank {
    width: 35px;
}

// EIP
.c-anchor-list__item {
    background-color: #eee;
    margin-bottom: 8px;
    padding: 4px;
}
.c-research-highlight__image {
    float: right;
    margin: 4px 0 20px 20px;
    width: 200px;
}
.c-research-highlight__credit {
    border-bottom: solid 1px #979797;
}

// Article page
.altmetric-see-more-details {
    font-size: 0.9375rem;
}
.c-profile__text dt,
.c-profile__text dd {
    font-size: 0.9375rem;
}
// City maps
.c-map-data {
    max-width: 970px;
    margin-left: auto;
    margin-right: auto;
}
.c-mapbox-attribution {
    max-width: 970px;
    margin-top: -10px;
    margin-left: auto;
    margin-right: auto;
}

// Static pages
.c-static-page__text {
    font-size: 1rem;
    line-height: 1.6;
}
// anchor component
.c-anchor__header {
    line-height: 1.2;
}
.c-anchor__links li {
    font-size: 0.875rem;
    line-height: 1.2;
    color: $t-context--color-blue;
    padding: 1rem 1.5rem 1rem 0rem;
}
.c-anchor__links li:first-child {
    margin-top: 0;
}
.c-faq-regions h3,
.c-faq-subjects h3 {
    font-size: 1rem;
}
.c-faq-journals,
.c-faq-regions,
.c-faq-subjects {
    font-size: 0.9375rem;
}

.c-faq-journals li span,
.c-faq-subjects li span {
    font-style: normal;
}
.c-brochure {
    width: 142px;
}
.c-brochure__link {
    font-size: 0.9375rem;
}
.c-brochure__image {
    width: 140px;
    border: solid 1px #ccc;
}

// Supplement
#sponsorTabContent {
    font-size: 15px;
}

// Custom graph/table
.c-switcher .btn-group .active {
    color: $t-color-white;
    background-color: #0062cc !important;
    border-color: #005cbf !important;
}

// Sidebar
.c-sidebar__heading {
    font-size: $context--font-size-sm;
}

// Sidebar supplement
.c-supplement a {
    color: #222;
}
.c-supplement__heading {
    font-family: $context--font-family-serif;
    font-size: $context--font-size-sm;
}
.c-supplement__image {
    margin-left: 10px;
    max-width: 100px;
}
.c-supplement__text {
    font-size: $context--font-size-xs;
}

// Sidebar article
.c-sidebar-article__item {
    border-bottom: solid 1px #ccc;
    min-height: 107px;
    padding: 16px 0;
}
.c-sidebar-article__title {
    font-size: $context--font-size-sm;
}
.c-sidebar-article__image {
    width: 100px;
    height: 75px;
    overflow-y: hidden;
}
.c-sidebar-article__text {
    font-size: $context--font-size-xs;
}

// News Archive Pagination
.c-pagination__link--active {
    background-color: #767676;
}

       
svg.c-breadcrumbs__chevron{
    vertical-align: unset !important;
}

h1+* , h2+* , h3+* , h4+* , h5+* , h6+*{
    -webkit-margin-before: 0 !important;
    margin-block-start: 0 !important;
}

//collapse card
.c-collapse--heading{
    padding: 20px 16px 20px 0px;
    border-bottom: 2px solid #F3F3F3;
}

// RESPONSIVE CSS

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .authors-bar {
        height: 16px;
        background: url("../../dist/img/icon-author-full.png") repeat-x left top;
    }
    // Static pages
    .c-brochure {
        width: 202px;
    }
    .c-brochure__image {
        width: 200px;
    }
}

// Small devices only (480px or smaller)
@media (max-width: 480px) {
}
@media (min-width: 768px) and (max-width: 992px) {
    #annual-tables-home{
        .c-panel--gray, .c-panel{
            height: 470px;
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    // Article page
    .c-profile__text dt,
    .c-profile__text dd {
        font-size: 1rem;
    }
    #index-badges-nri-banner {
        .c-panel--black--bg--img, .c-panel--gray {
            min-height: 450px;
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    #annual-tables{
        .c-hero{
            height: 300px;
        }
    }
    #annual-tables-home{
        .c-panel--gray, .c-panel{
            height: 440px;
        }
    }
    #index-badges-nri-banner {
        .c-panel--black--bg--img, .c-panel--gray {
            min-height: 390px;
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

//Datatable
table.dataTable thead > tr > th.sorting_asc:before, table.dataTable thead > tr > th.sorting_desc:after, table.dataTable thead > tr > td.sorting_asc:before, table.dataTable thead > tr > td.sorting_desc:after{
    opacity: 1 !important;
}
table.dataTable thead > tr > th.sorting:before, table.dataTable thead > tr > th.sorting_asc:before, table.dataTable thead > tr > th.sorting_desc:before, table.dataTable thead > tr > th.sorting_asc_disabled:before, table.dataTable thead > tr > th.sorting_desc_disabled:before, table.dataTable thead > tr > td.sorting:before, table.dataTable thead > tr > td.sorting_asc:before, table.dataTable thead > tr > td.sorting_desc:before, table.dataTable thead > tr > td.sorting_asc_disabled:before, table.dataTable thead > tr > td.sorting_desc_disabled:before{
    content: '\02C4';
    font-size: larger;
    opacity: 0.5;
}
table.dataTable thead > tr > th.sorting:after, table.dataTable thead > tr > th.sorting_asc:after, table.dataTable thead > tr > th.sorting_desc:after, table.dataTable thead > tr > th.sorting_asc_disabled:after, table.dataTable thead > tr > th.sorting_desc_disabled:after, table.dataTable thead > tr > td.sorting:after, table.dataTable thead > tr > td.sorting_asc:after, table.dataTable thead > tr > td.sorting_desc:after, table.dataTable thead > tr > td.sorting_asc_disabled:after, table.dataTable thead > tr > td.sorting_desc_disabled:after{
    content: '\02C5';
    font-size: larger;
    opacity: 0.5;
}
.dataTables_wrapper {
    .dataTables_info, .dataTables_paginate{
        font-size: 0.875rem;
        padding: 12px 0px 12px 0px;
        margin-block-start: 0rem;
    }
}
.dataTables_wrapper .dataTables_paginate .paginate_button{
    padding: 0px 8px 0px 8px !important;
    &:hover{
        background: none;
        border: none;
        color: #222 !important;
        padding: 0px 8px 0px 8px !important;
    }
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current{
    background: none;
    border-radius: 0px;
    border-color: #025E8D;
    color: #025E8D !important;
}
.dataTables_wrapper table.dataTable thead th table.dataTable tbody td{
    text-align: center !important;
}
.u-text-weight-600{
    font-weight: 600;
}


/* Ensure the label and select are displayed inline */
.dataTables_custom_info {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5em;
}
.dataTables_custom_info__elem {
    white-space: nowrap;
}
.dataTables_custom_length_select {
    min-width: 7em; 
}

/* Ensure the "entries" text is displayed inline and properly spaced */
.dataTables_length .c-more-link {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px; 
}
.dataTables_wrapper {
    .dataTables_custom_export {
        padding: 20px 0px 15px 0px;
    }
    .dataTables_custom_length_select{
        .selectron__trigger{
            &:focus{
                border: 1px solid #0275B0 !important;
                outline: 2px solid #D6EBF5 !important;
            }
        }
        .selectron__options{
            border-top: 1px solid #0275B0;
            border-radius: 0px;
            border-radius: 0px;
        }
        .selectron__options--is-open{
            border-radius: 0px !important;
        }
    }
}


//global position column styles
.c-count-share--column{
    border: 1px solid $t-color-light-blue-1;
    background: $t-color-light-blue-2;
    padding: 0.7rem 1rem 0.7rem 1rem;
    &--text__color{
        color: $t-color-blue-900;
        line-height: 2;
    }
    &--separator{
        border: 1px solid $t-color-light-blue-1;
        margin: 0px 8px 0px 8px;
    }
    &--vertical--separator{
        border-top: 1px solid $t-color-light-blue-1;
    }
}

// Ensure the background is white for the tooltip
.tooltip-inner {
    background-color: $t-color-primary-white;
    border: 1px solid $t-color-greyscale-400;
    color: $t-color-black;
    padding: 1rem;
}
.bs-tooltip-auto[x-placement^=right] .arrow::before,.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before,.bs-tooltip-auto[x-placement^=top] .arrow::before,.bs-tooltip-bottom .arrow::before,.bs-tooltip-top .arrow::before,.bs-tooltip-left .arrow::before, .bs-tooltip-right .arrow::before  {
  border-bottom-color: $t-color-greyscale-400;
}

//line chart legends with pointstyles and line
.c-line--dimensions{
    position: relative;
    width: 40px;
    height: 1px;
} 

.c-line-hollow-circle--background {
    background-color: var(--scientific-discipline-7c4a8d09ca3762af61e59520);
}
.c-line-container-circle {
    background-color: var(--scientific-discipline-e7f6c011776e8db7cd330b54);
}
.c-line-container-square {
    background-color: var(--scientific-discipline-7c4a8d09ca3762af61e59520);
}
.c-line-container-cross {
    background-color: var(--scientific-discipline-6a7ed890b6b541dab8d602c1);
    background: repeating-linear-gradient(to right, transparent, transparent 5px, var(--scientific-discipline-6a7ed890b6b541dab8d602c1) 5px, var(--scientific-discipline-6a7ed890b6b541dab8d602c1) 10px);
}
.c-line-container-rhombus {
    background-color: var(--scientific-discipline-9a0364b9e99bb480dd25e1f0);
}
.c-line-container-traingle {
    background-color: var(--scientific-discipline-3f5b99d1c2d24a7db57e1e74);
}

.c-line-dashed{      
    border-bottom: 1px dashed var(--scientific-discipline-3f5b99d1c2d24a7db57e1e74);
}
.c-line-circle {
    width: 8px;
    height: 8px;
    border: 1px solid var(--scientific-discipline-e7f6c011776e8db7cd330b54);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    overflow: hidden;
}
.c-line-hollow-circle{
    width: 8px;
    height: 8px;
    border: 1px solid var(--scientific-discipline-7c4a8d09ca3762af61e59520);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    overflow: hidden;
}
.c-line-circle--fill {
    width: 100%;
    height: 100%;
    background-color: $t-color-white;
    border-radius: 50%;
}
.c-line-cross {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.c-line-cross::before, .c-line-cross::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 10px;
    background-color: var(--scientific-discipline-6a7ed890b6b541dab8d602c1);
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
}
.c-line-cross::before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.c-line-cross::after {
    transform: translate(-50%, -50%) rotate(-45deg);
}
.c-line-circle {
    width: 8px;
    height: 8px;
    background-color: var(--scientific-discipline-e7f6c011776e8db7cd330b54);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.c-line-triangle {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid var(--scientific-discipline-3f5b99d1c2d24a7db57e1e74);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.c-line-square {
    width: 8px;
    height: 8px;
    background-color: var(--scientific-discipline-7c4a8d09ca3762af61e59520);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.c-line-rhombus {
    width: 8px;
    height: 8px;
    background-color: var(--scientific-discipline-9a0364b9e99bb480dd25e1f0);
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}

//annual table new layout for feature and content section
.c-list-columned--container{
    list-style-type: none;
    display: grid;
    @media (min-width: 480px) {
        grid-template-columns: repeat(auto-fill, minmax(26rem, 1fr));
    }
    @extend .pl-0;
}

.c-slice-heading--sub {
    border-bottom: 0.0625rem solid $t-border-color-primary;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 1.5rem;
    line-height: 1.4;
}

.c-panel--no-border-pd-1 {
    border: none;
    @extend .p-3;
}
.c-card--fill-gap {
    gap: 12px;
}

//Ensure selectron's div tags are displayed properly when TouchScreen is enabled
.u-button--is-touch {
    padding: 0.7rem 0.75rem !important;
}

.c-selectron--is-touch {
    line-height: 0.8 !important;
    height: 49px !important;
    display: list-item;
}

.u-font-base{
    font-size: $font-size-base;
}

// Account panel
.c-account-nav__menu {
    background-color: $t-color-black;
    color: $t-color-white;
    a {
        color: $t-color-white;
    }
}

.c-account-nav__anchor.eds-c-header__link::after {
    margin: 0;
    line-height: 1;
    transform: rotate(0deg);
    content: url("data:image/svg+xml,%3Csvg%20role%3D%22img%22%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20width%3D%2216%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m5.58578644%203-3.29289322-3.29289322c-.39052429-.39052429-.39052429-1.02368927%200-1.41421356s1.02368927-.39052429%201.41421356%200l4%204c.39052429.39052429.39052429%201.02368927%200%201.41421356l-4%204c-.39052429.39052429-1.02368927.39052429-1.41421356%200s-.39052429-1.02368927%200-1.41421356z%22%20transform%3D%22matrix(0%201%20-1%200%2011%203)%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
}

.c-account-nav__anchor.is-open.eds-c-header__link::after {
    transform: rotate(180deg);
}