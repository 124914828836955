%c-button-base {
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin: 0;
    position: relative;
    text-decoration: none;
    width: auto;
    font-family: sans-serif;
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 1;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 8px;
    transition: .25s ease, color .25s ease, border-color .25s ease;
    background-image: none;
    border: 1px solid $t-color-blue-900;    
    color: $t-color-blue-900;
    min-width: 4rem;
}

.c-button {
    &-group{
        position: relative;
        display: -ms-inline-flexbox;
        display: block;
        
        &__btn {
            @extend %c-button-base;

            &--hover, &:hover {
                background-color:  $t-color-blue-900;
                color: $t-color-white;
                text-decoration: none;
            }
            
            &--active {
                background-color:  $t-color-blue-900;
                color: $t-color-white;
            }

            &--spaced {
                margin-right: 10px;
            }
        }
    }
    &-switch{
        position: relative;
        display: -ms-inline-flexbox;
        display: inline-flex;
        &__btn {
            @extend %c-button-base;

            &:last-child {
                border-top-right-radius: 2px;
                border-bottom-right-radius: 2px;
            }
            &:first-child {
                border-top-left-radius: 2px;
                border-bottom-left-radius: 2px;
            }
            
            &--hover, &:hover {
                background-color:  $t-color-blue-900;
                color: $t-color-white;
                text-decoration: none;
            }
            
            &--active {
                background-color:  $t-color-blue-900;
                color: $t-color-white;
            }
        }
    }
}
