// Modal styles for NI search
.c-search-modal{    
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition: opacity 500ms;
    background: rgba(0, 0, 0, 0.7);
    visibility: hidden;
    opacity: 0;
    z-index: 100;
    &__body{
        margin: 0 auto;
        max-width: $context--container-width;
        padding: 32px 16px;
    }
    &__main{
        background: $t-color-brand-blue;
        color: $t-color-white;
        position: relative;
        top: 0;
        background-image: url(../../dist/img/hero-pattern.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right 19% bottom 0%;
    }
    &--visible{    
        visibility: visible;
        opacity: 1;
    }
    &__close{
        color: $t-color-white;
        font-weight: bold;
        font-size: 30px;
        text-decoration: none;
        &:hover{
            text-decoration: none;
        }
    }
}
