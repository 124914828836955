$font-size-base: 1rem;
$small-font-size: 0.875rem;
$enable-rounded: false;
$enable-caret:  false;

$navbar-padding-y: 0;
$navbar-padding-x: 0;

$navbar-light-color:rgba(0,0,0, 1);
$navbar-light-toggler-border-color: rgba(0,0,0, .2);

// Nav tabs
$nav-tabs-border-width: 0;
$nav-tabs-link-active-bg:transparent;

$nav-link-padding-y: 0.3125rem;
$nav-link-padding-x: 0.625rem;

$dropdown-min-width: 225px;
$dropdown-padding-y: 16px;
$dropdown-bg: #000;
$dropdown-border-width: 0;
$dropdown-item-padding-y: 8px;
$dropdown-item-padding-x: 16px;
$dropdown-link-color: #eee;
$dropdown-link-hover-color: #eee;
$dropdown-link-hover-bg: #000;
$dropdown-link-active-color: #069;
$dropdown-link-active-bg: #eee;

$input-btn-focus-width: 3px;
$input-btn-focus-color: #fece3e;

$container-max-widths: (
  md: 1140px
);

$list-group-bg: transparent;
$list-group-border-color: transparent;
$list-group-hover-bg: transparent;
$list-group-active-bg: transparent;

@import "~bootstrap/scss/bootstrap.scss";


// Customize Bootstrap

// Form
label {
  font-size: 0.8125rem;
}
// Progress bar in institution profile page
.progress {
  height: 1.5rem;
}
.bg-current {
  background-color: #bab1d4;
  color: #222;
}
.bg-domestic {
  background-color: darken(#638891,5%);
}
.bg-foreign {
  background-color:#a3e6d9;
  color: #222;
}
// Progress bar in country profile page
.bg-country1 {
  background-color: darken(#638891,5%);
}
.bg-country2 {
  background-color:#90b3a1;
  color: #222;
}
.bg-country3 {
  background-color:#a3e6d9;
  color: #222;
}
.bg-country4 {
  background-color:darken(#ce5b6f,5%);
}
.bg-country5 {
  background-color:#f9b6ab;
  color: #222;
}
.bg-country6 {
  background-color:#6c5893;
}
.bg-country7 {
  background-color:#e7c6be;
  color: #222;
}
.bg-country8 {
  background-color:#1e3d73;
}
.bg-country9 {
  background-color:#1072ba;
}
.bg-country10 {
  background-color:#8cdaf9;
  color: #222;
}

// Nab link tabs
.nav-tabs {
  border-bottom: solid 1px #ccc;
  flex-wrap: nowrap;
}
.nav-tabs .nav-link {
  color: #666;
  font-size: 0.875rem;
  line-height: 1.3;
  text-decoration: none;
}
.nav-tabs .nav-link.active {
  border-bottom: solid 2px #222;
  color: #222;
}
.nav-tabs .nav-link:hover {
  border-bottom: solid 2px rgba(34,34,34,.4);
  color: #222;
}
// table
.table .thead-dark th {
  vertical-align: middle;
}

// sticky position
.sticky-top {
  top: 10px;
  z-index: 999;
}

// institution A-Z navigation
.list-group-item.active {
    border-left: solid 5px #d5d5d5;
    color: #222;
    padding-left: 12px;
}

// button focus
.btn-primary:not(:disabled):not(.disabled):active:focus, 
.btn-primary:not(:disabled):not(.disabled).active:focus, 
.show>.btn-primary.dropdown-toggle:focus,
.btn-outline-secondary:focus, 
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 3px #fece3e;
}

// RESPONSIVE CSS

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
  .nav-tabs {
  flex-wrap: nowrap;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
  .nav-tabs .nav-link {
  font-size: 0.9375rem;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
}