// Customize header styles for Nature Index
// Modify styles for global frontend toolkit for Elements Design System
// Combine Elements with Twitter Bootstrap for mobile menu

// Header
.c-header__logo-container {
    padding-top: 5px;
    padding-bottom: 5px;
}
.c-header__link[aria-expanded=true] {
    background-color: #eee;
    color: color('blue');
    svg {
        fill: color('blue');
        transform: rotate(180deg);
    }
}
.c-header__menu--global {
    font-weight: 700;
}

// Dropdown menu styles
.c-header__menu .dropdown-menu {
    top: 45px;
}
.c-header__menu .dropdown-item {
    font-size: 0.875rem;
    font-weight: bold;
    &:hover {
    text-decoration: underline;
    }
}
.c-header__menu .dropdown-divider {
    margin: 5px 16px;
}

// RESPONSIVE CSS

// Mobile ~ small devices (up to 767px )
@media (max-width: 767px) {
    .c-header__menu {
        margin: 0 -16px;
    }
    .c-header__item {
        display: block;
        width: 100%;
    }
    .c-header__item:not(:first-child) {
        margin-left: 0;
    }
    .c-header__link {
        padding: 8px 16px;
    }

    .c-header__menu .dropdown-menu {
        top: 4px;
    }
    .c-header__menu .dropdown-menu.show {
        width: 100%;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
    .c-header {
        font-size: 0.875rem;
    }
    .c-header__show-text {
        display: none;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
    .c-header {
        font-size: 1rem;
    }
    .c-header__show-text {
        display: inline;
    }
}