.hs-firstname, .hs-lastname, .hs-email, .hs-country, .hs-phone, .hs-job_function__c, .hs-company, .hs-channeltype__c{
    .hs-input{
        width: 100% !important;
        border: 1px solid $t-color-greyscale-400;
        font-size: 1rem;
        padding: 0.5rem 0.75rem 0.5rem 0.75rem;
        line-height: 1.5rem;
        &:focus{
            outline: unset;
            border: 1.5px solid #0275B0;
        }
        &::placeholder{
            color: $t-color-greyscale-600;
        }
    } 
    label span{
        font-weight: 500;
        font-size: 0.875rem;
    }  
    .hs-fieldtype-intl-phone.hs-input select{
        width: 38% !important;
    }
    .hs-fieldtype-intl-phone.hs-input input{
        width: 60% !important;
    }
}    
.hs-error-msgs{
    color: $t-color-information-red;
}  
.hs-marketing_lead_reason{
    margin-top: 1rem;
}
.hs-button{
    color: white;
    background-color: #006699;
    background-image: none;
    border: 1px solid #006699;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin: 0;
    position: relative;
    text-decoration: none;
    width: auto;
    font-family: sans-serif;
    font-size: 1rem;
    line-height: 1.3;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0.5rem;
    transition: 0.25s ease, color 0.25s ease, border-color 0.25s ease;
    border-radius: 2px;
    margin-top: 1rem;
    &:hover{
        color: #006699;
        background-color: white;
        background-image: none;
        border: 1px solid #006699;
        text-decoration: underline;
    }
}
.hbspt-form fieldset{
    max-width: unset !important;
    margin-top: 0.5rem !important;
}
.hs-richtext p{
    font-size: 0.875rem;
    font-weight: 400;
}
.inputs-list {
    list-style-type: none;
    padding-inline-start: 0.25rem;
    margin-block-start: 0rem;
    margin-bottom: 0rem;
}
.hs-form-checkbox{
    span{
        font-size: 0.875rem;
        line-height: 1.5rem;
        vertical-align: text-bottom;
        margin-left: 1rem;
        color: #222;
    }  
    input[type="checkbox"] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 20px !important; 
        height: 20px !important; 
        border: 2px solid #014365; 
        background-color: #fff; 
        cursor: pointer;
        position: relative;
        outline: none;
    }    
    input[type="checkbox"]::before {
        content: '\2713'; 
        font-size: 16px; 
        color: transparent;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }    
    input[type="checkbox"]:checked::before {
        color: #fff;
    }
    input[type="checkbox"]:checked {
        background-color: #014365; 
    }
}