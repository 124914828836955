//Ordered list type
.c-ordered-list{
    counter-reset: list-counterr;
    list-style: none;         
    .c-ordered-list__item{
        margin: 0 0 0.5rem 0;
        counter-increment: list-counterr;
        position: relative;
        &::before{
            content: counter(list-counterr);
            font-size: 1.5rem;
            font-weight: bold;
            position: absolute;
            --size: 2rem;
            left: calc(-1 * var(--size) - 0.5rem);
            line-height: 1.75rem;
            width: var(--size);
            height: var(--size);
            top: 0;
            text-align: center;
        }
    }
    .c-ordered-list__item__badge {
        margin: 0 0 0.5rem 0;
        counter-increment: list-counterr;
        position: relative;
        &::before{
            content: counter(list-counterr);
            font-size: 0.75rem;
            font-weight: $t-typography-heading-level-1-default-font-weight;
            position: absolute;
            --size: 2rem;
            left: calc(-1 * var(--size) - 0.5rem);
            line-height: 1.5rem;
            width: 1.5rem;
            height: 1.5rem;
            top: 2px;
            text-align: center;
            background: $t-color-blue-900;
            color: $t-color-white;
            border-radius: 50%;
        }
    }
}
