$t-color-brand-blue: #001F2F;
$t-color-light-blue-1: #A7DAF4;
$t-color-light-blue-2: #F7FBFC;
$t-color-light-blue-3: #ebf0f3;
$t-color-greyscale-900: #f2f2f2;
$t-button-color-hover-outline: #fece3e;
$t-context--color-blue: #069;
$t-context--color-bluescale-600: #3365a4;
$t-color-information-redscale-600: rgb(255, 100, 100);
$t-color-dark-gray: rgba(51, 51, 51, 0.2);
$t-color-orange: #d74026;
$t-color-orange-900: #c75321;
$t-color-violet: #937c9f;
$t-color-yellow : #e6b800;
$t-color-turquiose-green : #338f8f;
$t-color-green-greenscale-600 : #497a3b;
$t-color-green-greenscale-500 : #3c763d;
$t-color-green : #37b067;
$t-color-rose : #b0375d;
$t-color-porcelain : #dba79c;
$t-color-olive : #9ca35a;
$t-color-brick-red: #a15348;
$t-color-bluescale-700: #444f75;
$t-color-bluescale-900: #1f4359;
$t-color-violetscale-900: #733d61;
