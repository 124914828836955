// News styles

@import 'news_iframe';

// Article container
.content {
    max-width: 1000px;
    margin: 0 auto;
}
.article-page--news .article-item {
    margin: 40px 0;
}
// Article type/date
.article-page--news .article__type {
    color: #000;
    display: inline-block;
    text-transform: uppercase;
    font-size: 1.0625rem;
    font-weight: 700;
    vertical-align: top;
}
.article-page--news .article__date {
    color: #1a1a1a;
    display: inline-block;
    font-size: 0.9375rem;
    font-weight: 400;
    margin-bottom: 10px;
    letter-spacing: .9px;
    text-transform: uppercase;
    vertical-align: baseline;
}
.article-page--news .article__date::before {
    content: "\00b7";
    margin-right: 10px;
    font-weight: 700;
}
.article-page--news .article-item__title {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: -1.5px;
    max-width: 85%;
    margin-bottom: 16px;
}
.article-page--news .article-item__teaser-text {
    font-family: Harding,Palatino,serif;
    line-height: 1.6;
    max-width: 85%;
    margin-bottom: 30px;
}
.article-page--news .bordered-container {
    border-bottom: 1px solid #979797;
    padding: 5px 0;
    margin-top: 15px;
    margin-bottom: 30px;
}

// Social icons
.c-social-icons__list {
    display: inline;
    margin-right: 10px;
    cursor: pointer;
}
.c-social-icons__list .icon--inline {
    position: relative;
    border: 1px solid #dadada;
    border-radius: 50%;
    padding: 10px 20px;

}
.c-social-icons__list .icon--inline svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 42px;
    height: 42px;
}

// Article body
.article__body {
    font-family: Harding,Palatino,serif;
    margin-bottom: 50px;
}
.article__body h2 {
    margin-bottom: 8px;
    margin-top: 32px;
}
.article__body .c-figure {
    margin-left: -15px;
    margin-right: -15px;
}
.article__body .c-figure__image {
    width: 100%;
}
.article__body .c-figure__caption, 
.article__body .c-figure__credit {
    font-size: 0.8125rem;
    margin: 10px 20px;
    color: #666;
    border-bottom: 1px solid #979797;
    padding-bottom: 5px;
}

// Temporary styles to hide title, standfirst, author we use in the old article template
// Need to delete these old items in the CMS after the site renewal
.article__body h1,
.article__body .standfirst,
.article__body .pubdate,
.article__body #author,
.article__body #author .name {
    display: none;
}
// Hide new main figure for old markup
.article__body .main-image {
    display: none;
}
.article__body img {
    margin-bottom: 8px;
}
.article__body figcaption,
.article__body .caption,
.article__body .credit {
    font-size: 0.8125rem;
    margin: 0;
    color: #666;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
.article__body .fig-box,
.article__body .figure {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 0.8125rem;
    color: #666;
    margin-bottom: 48px;
    border-bottom: solid 1px #979797;
    padding-bottom: 8px;
}
.article__body figure {
    margin-bottom: 48px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 0.8125rem;
    color: #666;
}
// Altmetric embed handing
.article__body .altmetric-embed {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 0.75rem;
    color: #666;
    margin-bottom: 48px;
}
.article__body .figure img {
width: 100%;
}
.article__body .fig-box,
.article__body .fig-box img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.article__body .fig-box .caption,
.article__body .fig-box .credit {
    text-align: center;
}

// Recomended box
.article__recommended {
    width: 230px;
    padding: 10px;
    background-color: #ebebeb;
    position: relative;
    float: left;

    img {
        background-color: #fff;
    }
}

.article__recommended:before {
    font-size: 13px;
    font-size: .8125rem;
    position: absolute;
    top: 10px;
    left: -10px;
    content: "RELATED";
    text-transform: uppercase;
    background-color: #000;
    color: #fff;
    padding: 0 10px 0 25px;
    letter-spacing: 1px;
    font-weight: 700;
    line-height: 1.6;
}

.article__recommended:after {
    content: "";
    position: absolute;
    top: 31px;
    left: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 5px 0;
    border-color: transparent #828282 transparent transparent;
}

// Sidebar: Tags
.article__aside {
    font-size: 0.8125rem;
}
.c-pill {
    background-color: #ebf6ff;
    border-radius: 20px;
    cursor: pointer;
    padding: 5px 10px;
    font-weight: 700;
    color: #069;
    margin-right: 4px;
    margin-bottom: 10px;
    white-space: nowrap;
    display: inline-block;
}
.c-pill:hover {
    text-decoration: underline;
}

// By keyword list
#by-keyword .c-kyeword-list__item  {
    font-size: 1.0625rem;
}
// Sajari search input
#nav-search-box {
    background-color: #fff;
}

.article-page--news{
    .c-table-scroll-wrapper {
        position: relative;
    }

    .c-table-scroll-wrapper__content{
        table{
            margin-block-start: 0rem;
        }

        table,
        tr,
        th,
        td {
            border: 0;
        }

        tbody tr:nth-of-type(odd) {
            background-color: #f7f7f7;
        }

        tbody tr:nth-of-type(even) {
            background-color: #eee;
        }

        .table__title, .table__caption {
            letter-spacing: .5px;
        }

        .table__title {
            font-size: 1.25rem;
            font-weight: 700;
            padding: 20px 8px 15px;
            margin: 0;
            line-height: 1.4;
            border-top: 1px solid #000;
        }

        .table__caption {
            font-size: .875rem;
            padding: 10px 8px;
            margin-block-start: 0;
            line-height: 1.6;
            background-color: #f7f7f7;
        }
    }

    .c-table-scroll-wrapper__fade::after {
        content: "";
        width: 30px;
        background-image: linear-gradient(to right,rgba(255,255,255,0),rgba(255,255,255,.6),#fff);
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 1;
        transition: opacity .25s ease-in-out;
        will-change: opacity;
    }
}
// RESPONSIVE CSS

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
    // Article body
    .align-left {
        width: 60.2%;
        float: left;
        max-width: 600px;
    }
    // Sidebar
    .align-right {
        float: right;
        width: 31.2%;
        margin-left: 8.6%;
        max-width: 320px;
    }
    .article-page--news .article-item__title {
        font-size: 2.5rem;
    }
    .article-page--news .article-item__teaser-text {
        margin-bottom: 28px;
        font-size: 1.4375rem;
    }
    .article__body .c-figure {
        margin-left: 0;
        margin-right: 0;
    }
    .article__body .c-figure__caption, 
    .article__body .c-figure__credit {
        margin: 10px 0;
    }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
}
